<template>
  <div class="cityEa zh">
    <el-container>
      <el-header style="width: 100%; height: 30%;">
        <div>
          <vheader class="hidden-sm-and-down"></vheader>
          <ivheadera class="hidden-md-and-up"></ivheadera>
          <div class="ec-top">
            <img src="../../assets/images/en/A/h-bg2.png" alt class="bg-a">
            <img src="../../assets/images/en/A/bg-b.png" alt class="bg-b">
            <div class="ec-top-t">
              <div class="ec-top-txt">
                <p>City Accounts</p>
                <span>城市号</span>
              </div>
            </div>
          </div>
        </div>
      </el-header>
      <el-main class="eaea">
        <div class="w">
          <div class="eaea-mif mif">
            <div class="ec-top-foot">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item ><router-link to="/">首页</router-link></el-breadcrumb-item>
                  <el-breadcrumb-item>城市号</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            <div class="map">
              <img src="../../assets/images/E/map.png" class="map-i" alt>
            </div>
            <div class="mi-m">
              <div class="mi-up">
                <img src="../../assets/images/E/gemp.png" class="gemp" alt>
                <div class="gemp-i">从这里拥抱世界</div>
                <div
                  class="gemp-t"
                >城市号以深圳的国际友城网络为基础，邀请国际知名城市以城市为单位入驻，拥有自己的“城市号”并通过平台实现城市间个性化的宣介和信息互动。用户可以足不出户，通过城市号中的城市体验线路，以及由视频、图片、文字组成的多媒体内容，对城市进行多维度的了解。</div>
                <!-- 4 -->
                <el-popover
                  class="item-a"
                  placement="right"
                  width="280"
                  trigger="hover">
                  <div v-popover class="pop" @click="goto(citynap[4].SEECITYNUM_ID)">
                    <img :src="citynap[4].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b pop-b-s " >
                      <div class="pop-a">{{citynap[4].URL}}</div>
                      <div class="pop-t">
                        <h4>{{citynap[4].CITYNAME}}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="as floate" @click="goto(citynap[4].SEECITYNUM_ID)">
                      <p>{{citynap[4].CITYNAME}}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 10 -->
                <el-popover
                  class="item-b"
                  placement="right"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop"  @click="goto(citynap[10].SEECITYNUM_ID)">
                    <img :src="citynap[10].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[10].URL}}</div>
                      <div class="pop-t">
                        <h4>{{citynap[10].CITYNAME}}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="item-b as floate" @click="goto(citynap[10].SEECITYNUM_ID)">
                      <p>{{citynap[10].CITYNAME}}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 2 -->
                <el-popover
                  class="item-c"
                  placement="top"
                  width="280"
                  trigger="hover">
                  <div v-popover class="pop" @click="goto(citynap[2].SEECITYNUM_ID)">
                    <img :src="citynap[2].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[2].URL}}</div>
                      <div class="pop-t">
                        <h4>{{citynap[2].CITYNAME}}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="as floate" @click="goto(citynap[2].SEECITYNUM_ID)">
                      <p>{{citynap[2].CITYNAME}}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 1 -->
                <el-popover
                  class="item-i"
                  placement="top"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop" @click="goto(citynap[1].SEECITYNUM_ID)">
                    <img :src="citynap[1].IMAGE  || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[1].URL}}</div>
                      <div class="pop-t">
                        <h4>{{citynap[1].CITYNAME}}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="item-i as floate" @click="goto(citynap[1].SEECITYNUM_ID)">
                      <p>{{citynap[1].CITYNAME}}</p>
                      <img src="../../assets/images/E/d2.png" width="23%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 8 -->
                <el-popover
                  class="item-d"
                  placement="left"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop" @click="goto(citynap[8].SEECITYNUM_ID)">
                    <img :src="citynap[8].IMAGE  || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[8].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[8].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div @click="goto(citynap[8].SEECITYNUM_ID)" class="item-d as floate">
                      <p>{{ citynap[8].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 9 -->
                <el-popover
                  class="item-e"
                  placement="left"
                  width="280"
                  trigger="hover">
                  <div v-popover class="pop" @click="goto(citynap[9].SEECITYNUM_ID)">
                    <img :src="citynap[9].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[9].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[9].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="we as floate" @click="goto(citynap[9].SEECITYNUM_ID)">
                      <p>{{ citynap[9].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 6 -->
                <el-popover
                  class="item-f"
                  placement="left"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop" @click="goto(citynap[6].SEECITYNUM_ID)">
                    <img :src="citynap[6].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[6].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[6].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="as floate" @click="goto(citynap[6].SEECITYNUM_ID)">
                      <p>{{ citynap[6].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 5 -->
                <el-popover
                  class="item-h"
                  placement="left"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop" @click="goto(citynap[5].SEECITYNUM_ID)">
                    <img :src="citynap[5].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[5].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[5].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="item-h as floate" @click="goto(citynap[5].SEECITYNUM_ID)">
                      <p>{{ citynap[5].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 3 -->
                <el-popover
                  class="item-j"
                  placement="bottom"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop"  @click="goto(citynap[3].SEECITYNUM_ID)">
                    <img :src="citynap[3].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[3].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[3].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="item-j as floate"  @click="goto(citynap[3].SEECITYNUM_ID)">
                      <p>{{ citynap[3].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 0 -->
                <el-popover
                  class="item-k"
                  placement="top"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop"  @click="goto(citynap[0].SEECITYNUM_ID)">
                    <img :src="citynap[0].IMAGE || ''"  onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[0].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[0].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="item-k as floate"  @click="goto(citynap[0].SEECITYNUM_ID)">
                      <p>{{ citynap[0].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 7 -->
                <el-popover
                  class="item-l"
                  placement="top"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop"  @click="goto(citynap[7].SEECITYNUM_ID)">
                    <img :src="citynap[7].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[7].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[7].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="as floate"  @click="goto(citynap[7].SEECITYNUM_ID)">
                      <p>{{ citynap[7].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
                <!-- 11 -->
                <el-popover
                  class="item-n"
                  placement="top"
                  width="280"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                  <div v-popover class="pop"  @click="goto(citynap[11].SEECITYNUM_ID)">
                    <img :src="citynap[11].IMAGE || ''" onerror="this.style.display='none'" alt width="100%">
                    <div class="pop-b">
                      <div class="pop-a">{{citynap[11].URL}}</div>
                      <div class="pop-t">
                        <h4>{{ citynap[11].CITYNAME }}</h4>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference">
                    <div class="as floate"  @click="goto(citynap[11].SEECITYNUM_ID)">
                      <p>{{ citynap[11].CITYNAME }}</p>
                      <img src="../../assets/images/E/d2.png" width="20%" alt>
                    </div>
                  </el-button>
                </el-popover>
              </div>
              <div class="mis-txt mis-down">
                <img src="../../assets/images/E/citymap.png" alt width="100%">
                <div class="gemp-i-down">从这里拥抱世界</div>
                <div
                  class="gemp-t-down"
                >城市号以深圳的国际友城网络为基础，邀请国际知名城市以城市为单位入驻，拥有自己的“城市号”并通过平台实现城市间个性化的宣介和信息互动。用户可以足不出户，通过城市号中的城市体验线路，以及由视频、图片、文字组成的多媒体内容，对城市进行多维度的了解。</div>
                <div class="mis-txt-i">
                  <div class="cityname" v-for="(item,index) in citynap" :key="index" @click="goto(item.SEECITYNUM_ID)">
                    <p>{{ item.CITYNAME }}</p>
                  </div>
                </div>
              </div>
            </div>
           <div class="news" v-show="true">
              <img class="ims" src="../../assets/images/E/news1.png" alt>
            </div>
            <div class="foots" v-show="true">
              <div class="new-list">
                <el-col :xs="24" :sm="24" :md="8" v-for="(item,index) in cityList.slice((pagecur - 1) * pagecnt, pagecur * pagecnt)" :key="index">
                  <router-link :to="{name:'cityE5', params: {newId:item.SEECITYNUMMX_ID}}">
                  <div class="foots-i">
                    <div class="img"><img :src="item.newsimage || ''" alt=""  :title="'图片来源：' + item.Picture"></div>
                    <div class="foots-p">
                      <span>{{item.newstitle}}</span>
                    </div>
                  </div>
                  </router-link>
                </el-col>
              </div>
<!--              <div class="foots-i" @click="gotoe(citywenb.SEECITYNUMMX_ID)">-->
<!--                <img :src="citywenb.newsimage || ''" alt>-->
<!--                <div class="foots-p">-->
<!--                  <span>{{citywenb.newstitle}}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="foots-i" @click="gotoe(citywenc.SEECITYNUMMX_ID)">-->
<!--                <img :src="citywenc.newsimage || ''" alt>-->
<!--                <div class="foots-p">-->
<!--                  <span>{{citywenc.newstitle}}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="page-con">-->
                <el-pagination
                  background
                  hide-on-single-page
                  layout="prev, pager, next"
                  @current-change="handleCurrentChange"
                  :total="cityList.length"
                  :page-size="pagecnt"
                  :pager-count="4"
                  :current-page="pagecur"
                  @prev-click="prev()"
                  @next-click="next()"
                ></el-pagination>
<!--              </div>-->
            </div>
          </div>
          <gotop></gotop>
        </div>
      </el-main>
      <el-footer>
        <vfooter class="hidden-sm-and-down"></vfooter>
        <ivfooter class="hidden-md-and-up"></ivfooter>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Gotop from '../../components/gotop'
import { citynap, citylist } from '../../api'

export default {
  data () {
    return {
      citynap: {},
      id: {},
      imgs: {},
      cityList: [],
      pagecur: 1,
      pagecnt: 12
      // id: parseInt(this.$route.params.id)
    }
  },
  components: {
    Gotop
  },
  mounted () {
    citynap().then(res => {
      this.citynap = res.data
    })
    citylist().then(res => {
      this.cityList = res.data
    })
  },
  methods: {
    prev () {
      this.pagecur--
    },
    next () {
      this.pagecur++
    },
    handleCurrentChange (val) {
      this.pagecur = val
    },
    goto (id) {
      this.$router.push({ name: `cityE3`, query: { id: id } })
      // console.log(id)
    },
    gotoe (id) {
      let newId = 'new' + id
      this.$router.push({ name: `cityE5`, params: { newId: newId } })
    }
  }
}
</script>

<style scoped src='../../assets/css/CityE1.css' >
</style>
<style lang="less">
.cityEa.zh{
  a{
    color: inherit;
  }
  .foots .el-pagination{
    margin-top: 0.5rem;
    width: 100%;
  }
  .foots .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    margin: 0;
    border-radius: 0;
    border: 0.0185rem solid #e2e2e2;
    background:#fff;
    padding: 0 .24rem;
  }
  .foots .el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #00B6CD;
    border: 0.0185rem solid #00B6CD;
  }
  .foots .el-icon-arrow-left:before{
    content: '上一页';
    font-weight: normal;
  }
  .foots .el-icon-arrow-right:before{
    content: '下一页';
    font-weight: normal;
  }
  .el-popover--plain {
    padding: 0;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover {
    color: #fff600;
  }
}

</style>
